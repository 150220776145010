/**
 * Do not remove any keys!!
 * The shape of this file is to remain the same so that all options are know.
 *
 * If a key does not have a value, set the value to undefined.
 */
module.exports = {
  communityName: "The Village on 4th",
  gtagId: "G-YJEPV0417N",
  logo: {
    src: "https://cdn.maxxpm.com/villageon4th/logosandbanners/Village on 4th Logo.png",
    alt: "The Village on 4th Logo",
  },
  nav: {
    bannerSubtitle: undefined,
    bannerTitle: undefined,
    bannerCTA: undefined,
  },
  footer: {
    address: "600 W 400 S",
    cityStateZip: "Springville, UT 84663",
    phone: "(385) 770-6950",
    copyrightYear: "2024",
    logo: "https://cdn.maxxpm.com/villageon4th/logosandbanners/V4th 35px.png",
  },
  listMarkerSrc: "https://cdn.maxxpm.com/villageon4th/logosandbanners/Village on 4th Logo bullet point.png",
  hasCalendlyPopup: false,
  calendlyLink: undefined,
  home: {
    banner: {
      image: "https://cdn.maxxpm.com/villageon4th/Exterior/DSC_0290.jpg",
      video: undefined,
      title: "A New Living Experience",
      subtitle: "Spacious Modern Living",
    },
    aboutUs: {
      paragraphs: [
        "South of Provo, sandwiched between Utah Lake and the Wasatch Front is Springville, a residential city best known for its thriving art and music scene. Home to the Springville Museum of Art, both residents and visitors have the opportunity to immerse themselves in the local art culture. The European-inspired museum features a diverse, rotating display of exhibitions, with work from domestic and international artists, interactive exhibits, and more. For the more outdoor-minded folk, consider a day trip to Wayne Bartholomew Family Park. The expansive recreational space features a volleyball court, ring toss court, multiple covered pavilions, a three-acre fishing pond, and a great network of running trails.",
        "The Village on 4th is a premier apartment community located off of 4th South, just minutes away from I-15. Our gorgeous development offers residents a diverse array of onsite amenities, including a splash-pad and exclusive clubhouse. This excellent location boasts astonishing views of the Wasatch Mountain Range from your window. With Art City Main Street just a few blocks away, residents can take advantage of all of the first-rate entertainment, world-class outdoor activities, and delicious restaurants that Springville has to offer.",
        "All floor plans include a large open kitchen with granite and stainless steel appliances, an open dining area with a large living room, a covered walk-out balcony or porch, a walk-in laundry room, an oversized master bedroom, and other finishing touches you’ll be proud to live in.",
      ],
      image: {
        src: "https://cdn.maxxpm.com/villageon4th/Exterior/DSC_0301.jpg",
        alt: "Village on 4th Clubhouse",
      },
    },
    floorplanTitle: "Explore Our Apartment and Clubhouse Floorplans",
    floorplans: [
      {
        hasImage: true,
        name: "3 Bed 2 Bath",
        image: {
          src: "https://cdn.maxxpm.com/villageon4th/3bed2bath/top view.jpg",
          alt: "Apartment Floor Plan",
        },
        hasWalkthrough: true,
        walkthrough: {
          src: "https://my.matterport.com/show/?m=C6V5oa3ezTW",
          title: "Apartment 3d walkthrough",
        },
      },
      {
        hasImage: true,
        name: "Clubhouse",
        image: {
          src: "https://cdn.maxxpm.com/villageon4th/3bed2bath/floorplan of clubhouse.jpg",
          alt: "Clubhouse Floor Plan",
        },
        hasWalkthrough: false,
      },
    ],
    midContainerImage: "https://cdn.maxxpm.com/villageon4th/logosandbanners/dad holding son flying.jpg",
    imgRow: [
      {
        src: "https://cdn.maxxpm.com/villageon4th/Exterior/DSC_0294.jpg",
        alt: "stock photo image banner",
      },
      {
        src: "https://cdn.maxxpm.com/villageon4th/Exterior/DSC_0311.jpg",
        alt: "stock photo image banner",
      },
      {
        src: "https://cdn.maxxpm.com/villageon4th/Exterior/DSC_0296.jpg",
        alt: "stock photo image banner",
      },
    ],
    animalPolicy: {
      img: {
        src: "https://cdn.maxxpm.com/stockphotos/bulldog+on+legs.jpg",
        alt: "Animal policy stock photo",
      },
      policies: [
        "No more than 2 animals are allowed per property",
        "Cats/Dogs: $250/animal*",
        "$50/animal/month for animals under 50lbs*",
        "$75/animal/month for animals 50lbs+*",
        "One-time $75/animal DNA testing fee*",
        "Caged animals: $25/animal/month*",
        "Fish tanks are not permitted",
        "Rabbits and rodents of any kind are not permitted",
      ],
      breedRestrictions: [
        "Pit Bulls & Staffordshire Terriers",
        "Doberman Pinschers",
        "Rottweilers",
        "Chows",
        "Great Danes",
        "Presa Canarios/ Mastiffs",
        "Akitas",
        "Alaskan Malamutes",
        "Huskies",
        "German Shepherds",
        "Shar Peis",
        "Wolf-Hybrids",
      ],
    },
  },
  availability: {
    banner: {
      image: "https://cdn.maxxpm.com/villageon4th/3bed2bath/angled view 2.jpg",
      video: undefined,
      title: "Available Units",
      subtitle:
        "Unit availability is updated often from Monday-Friday during business hours. Evenings and weekends may not reflect accurate availability dates.",
    },
    hasWaitingList: false,
    waitingListJotformId: undefined,
    propertyGroup: "LEADS-V4",
  },
  faq: {
    banner: {
      image: "https://cdn.maxxpm.com/villageon4th/Exterior/DSC_0290.jpg",
    },
    dnaRequired: true,
  },
};
